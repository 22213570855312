import { IFacility, ITransitionOfCare, ITransitionOfCareItems } from "state/types/toc-management-slice.type";
import "./toc-change-modal.styles.scss";
import Modal from "components/Modal/modal.component";
import ModalHeader from "components/ModalHeader/modal-header.component";
import { useCallback, useEffect, useState } from "react";
import { PAC_TYPE } from "pages/task-management/transition-of-care/constants/index.constant";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";

interface ITocChangeModalProps {
  isOpen: boolean;
  facilities: IFacility[];
  historyOfToc: ITransitionOfCare;
  toc: ITransitionOfCare;
  onClose: (isOpen: boolean) => void;
}
const TocChangeModal = ({ onClose, isOpen, historyOfToc, toc, facilities }: ITocChangeModalProps) => {
  const [proposedChanges, setProposedChanges] =
    useState<Record<string, { providerName: string; los: number; providerId: number }>>();
  const [approvedChanges, setApprovedChanges] =
    useState<Record<string, { providerName: string; los: number; providerId: number }>>();

  const getProviderName = useCallback(
    (providerId: number) => {
      if (facilities && facilities.length > 0 && providerId !== -1) {
        return facilities.find((el) => el.id === providerId)?.providerName;
      } else {
        return "";
      }
    },
    [facilities]
  );
  const getTargetLos = useCallback((los: number) => {
    if (los === -1) {
      return "";
    }
    return los.toString();
  }, []);

  const intializePropsedChanges = useCallback(() => {
    if (historyOfToc.transitionOfCareItems && historyOfToc.transitionOfCareItems.length > 0) {
      historyOfToc.transitionOfCareItems.forEach(({ providerId, targetLos, pacTypeName }: ITransitionOfCareItems) => {
        if (pacTypeName === PAC_TYPE.SNF) {
          setProposedChanges((prev: any) => {
            return {
              ...prev,
              snf: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.IRF) {
          setProposedChanges((prev: any) => {
            return {
              ...prev,
              irf: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.HH) {
          setProposedChanges((prev: any) => {
            return {
              ...prev,
              hh: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.OPT) {
          setProposedChanges((prev: any) => {
            return {
              ...prev,
              opt: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.MPT) {
          setProposedChanges((prev: any) => {
            return {
              ...prev,
              mpt: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.HOME_SERVICE) {
          setProposedChanges((prev: any) => {
            return {
              ...prev,
              homeService: {
                providerId,
                providerName: "",
                los: targetLos,
              },
            };
          });
        }
      });
    }
  }, [setProposedChanges, getProviderName, getTargetLos, historyOfToc.transitionOfCareItems]);

  const intializeApprovedChanges = useCallback(() => {
    if (toc.transitionOfCareItems && toc.transitionOfCareItems.length > 0) {
      toc.transitionOfCareItems.forEach(({ providerId, targetLos, pacTypeName }: ITransitionOfCareItems) => {
        if (pacTypeName === PAC_TYPE.SNF) {
          setApprovedChanges((prev: any) => {
            return {
              ...prev,
              snf: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.IRF) {
          setApprovedChanges((prev: any) => {
            return {
              ...prev,
              irf: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.HH) {
          setApprovedChanges((prev: any) => {
            return {
              ...prev,
              hh: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.OPT) {
          setApprovedChanges((prev: any) => {
            return {
              ...prev,
              opt: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.MPT) {
          setApprovedChanges((prev: any) => {
            return {
              ...prev,
              mpt: {
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.HOME_SERVICE) {
          setApprovedChanges((prev: any) => {
            return {
              ...prev,
              homeService: {
                providerId,
                providerName: "",
                los: targetLos,
              },
            };
          });
        }
      });
    }
  }, [setApprovedChanges, getProviderName, getTargetLos, toc.transitionOfCareItems]);

  useEffect(() => {
    if (historyOfToc && toc) {
      intializePropsedChanges();
      intializeApprovedChanges();
    }
  }, [intializeApprovedChanges, intializePropsedChanges, toc, historyOfToc]);

  const checkIfDifferenceExists = (
    proposed: { providerName: string; los: number; providerId: number },
    approved: { providerName: string; los: number; providerId: number }
  ) => {
    if (!proposed && !approved) {
      return false;
    }
    return (
      (!proposed && approved) ||
      (proposed && !approved) ||
      proposed.providerName !== approved.providerName ||
      proposed.los !== approved.los ||
      proposed.providerId !== approved.providerId
    );
  };

  const getProviderNameText = (providerName: string) => {
    if (providerName.length <= 35) {
      return <span className="text">{providerName}</span>;
    } else {
      return (
        <>
          <span className="text" data-tip data-for={providerName}>
            {providerName.substring(0, 35).trim() + "..."}
          </span>
          <CustomToolTip text={providerName} id={providerName} place={"bottom"} />
        </>
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="center toc-change-view-container"
      contentClass="toc-change-view-main"
      modalHeader={
        <ModalHeader iconClassName="cross-container" heading="TOC Plan Updates" onCloseModal={() => onClose(false)} />
      }
    >
      <div className="toc-change-view-content">
        <div className="toc-change-table">
          <div className="header">
            <div className="location-cell">Location</div>
            <div className="proposed-cell">Proposed</div>
            <div className="approved-cell">Approved</div>
          </div>
          <div className="body">
            <div className={historyOfToc.anticipatedAcuteLOS !== toc.anticipatedAcuteLOS ? "row change" : "row"}>
              <div className="location-cell">
                <span className="text">Acute LOS</span>
              </div>
              <div className="cell">
                <span className="text">{historyOfToc.anticipatedAcuteLOS}</span>
              </div>
              <div className="cell">
                <span className="text">{toc.anticipatedAcuteLOS}</span>
              </div>
            </div>
            <div
              className={
                proposedChanges && approvedChanges && checkIfDifferenceExists(proposedChanges.irf, approvedChanges.irf)
                  ? "row change"
                  : "row"
              }
            >
              <div className="location-cell">
                <span className="text">IRF</span>
                <span className="sub-text">LOS</span>
              </div>
              <div className="cell">
                {proposedChanges?.irf && proposedChanges?.irf.providerName?.length ? (
                  <>
                    {getProviderNameText(proposedChanges?.irf.providerName)}
                    <span className="sub-text">{proposedChanges?.irf.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
              <div className="cell">
                {approvedChanges?.irf && approvedChanges?.irf.providerName?.length ? (
                  <>
                    {getProviderNameText(approvedChanges?.irf.providerName)}
                    <span className="sub-text">{approvedChanges?.irf.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
            </div>
            <div
              className={
                proposedChanges && approvedChanges && checkIfDifferenceExists(proposedChanges.snf, approvedChanges.snf)
                  ? "row change"
                  : "row"
              }
            >
              <div className="location-cell">
                <span className="text">SNF</span>
                <span className="sub-text">LOS</span>
              </div>
              <div className="cell">
                {proposedChanges?.snf && proposedChanges?.snf.providerName.length ? (
                  <>
                    {getProviderNameText(proposedChanges?.snf.providerName)}
                    <span className="sub-text">{proposedChanges?.snf.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
              <div className="cell">
                {approvedChanges?.snf && approvedChanges?.snf.providerName?.length ? (
                  <>
                    {getProviderNameText(approvedChanges?.snf.providerName)}
                    <span className="sub-text">{approvedChanges?.snf.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
            </div>
            <div
              className={
                proposedChanges && approvedChanges && checkIfDifferenceExists(proposedChanges.hh, approvedChanges.hh)
                  ? "row change"
                  : "row"
              }
            >
              <div className="location-cell">
                <span className="text">HH</span>
                <span className="sub-text">Visits</span>
              </div>
              <div className="cell">
                {proposedChanges?.hh && proposedChanges?.hh.providerName?.length ? (
                  <>
                    {getProviderNameText(proposedChanges?.hh.providerName)}
                    <span className="sub-text">{proposedChanges?.hh.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
              <div className="cell">
                {approvedChanges?.hh && approvedChanges?.hh.providerName?.length ? (
                  <>
                    {getProviderNameText(approvedChanges?.hh.providerName)}
                    <span className="sub-text">{approvedChanges?.hh.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
            </div>

            <div
              className={
                proposedChanges && approvedChanges && checkIfDifferenceExists(proposedChanges.mpt, approvedChanges.mpt)
                  ? "row change"
                  : "row"
              }
            >
              <div className="location-cell">
                <span className="text">M-PT</span>
                <span className="sub-text">Visits</span>
              </div>
              <div className="cell">
                {proposedChanges?.mpt && proposedChanges?.mpt.providerName?.length ? (
                  <>
                    {getProviderNameText(proposedChanges?.mpt.providerName)}
                    <span className="sub-text">{proposedChanges?.mpt.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
              <div className="cell">
                {approvedChanges?.mpt && approvedChanges?.mpt.providerName?.length ? (
                  <>
                    {getProviderNameText(approvedChanges?.mpt.providerName)}
                    <span className="sub-text">{approvedChanges?.mpt.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
            </div>

            <div
              className={
                proposedChanges && approvedChanges && checkIfDifferenceExists(proposedChanges.opt, approvedChanges.opt)
                  ? "row change"
                  : "row"
              }
            >
              <div className="location-cell">
                <span className="text">OPT</span>
                <span className="sub-text">Visits</span>
              </div>
              <div className="cell">
                {proposedChanges?.opt && proposedChanges?.opt.providerName?.length ? (
                  <>
                    {getProviderNameText(proposedChanges?.opt.providerName)}
                    <span className="sub-text">{proposedChanges?.opt.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
              <div className="cell">
                {approvedChanges?.opt && approvedChanges?.opt.providerName?.length ? (
                  <>
                    {getProviderNameText(approvedChanges?.opt.providerName)}
                    <span className="sub-text">{approvedChanges?.opt.los}</span>
                  </>
                ) : (
                  <span className="text">-</span>
                )}
              </div>
            </div>
            <div
              className={
                proposedChanges?.homeService?.providerId !== approvedChanges?.homeService?.providerId
                  ? "row change"
                  : "row"
              }
            >
              <div className="location-cell">
                <span className="text">Home without services</span>
              </div>
              <div className="cell">
                {!proposedChanges?.homeService || proposedChanges?.homeService.providerId === -1 ? (
                  <span className="text">No</span>
                ) : (
                  <span className="text">Yes</span>
                )}
              </div>
              <div className="cell">
                {!approvedChanges?.homeService || approvedChanges?.homeService.providerId === -1 ? (
                  <span className="text">No</span>
                ) : (
                  <span className="text">Yes</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TocChangeModal;
