import CommonState from "components/common-state/common-state.component";
import {
  PlannedUnplannedEnum,
  RiskLevelEnum,
  TOC_LISTING_HEADERS,
  TocStatusEnum,
  cancelSubheading,
  resetSubheading,
  submitSubheading,
  tocTitle,
} from "./constants/index.constant";
import Button from "components/Button/button.component";
import Header from "../header/header.component";
import { RevisionReasonModal } from "./modals/revision-reason-modal/revision-reason-modal.component";
import { TransitionOfCarePresentationProps } from "./transition-of-care.props";
import { CommonStateType } from "components/common-state/common-state.enum";
import AddServiceModal from "components/Modal/add-service-modal/add-service-modal.container";
import { useDispatch, useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import GenericPopupModal from "components/Modal/GenericPopupModal/generic-popup-modal.component";
import {
  deleteToc,
  getTOCManagementState,
  getTocEditForm,
  setTocEditForm,
} from "state/feature/toc-management/toc-management.slice";
import TocReasonModal from "components/Modal/toc-reason-modal/toc-reason-modal.container";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { setLockState } from "state/feature/task-management/task-management.action";
import { getBrowserState, removeTab } from "state/feature/task-management/task-management.slice";

const TransitionOfCarePresentation = (props: TransitionOfCarePresentationProps) => {
  const { modal } = useSelector(getCommonState);
  const { riskLevel, isPlanned } = useSelector(getTOCManagementState);
  const {
    hasScrollBar,
    reasonModalPayload,
    featureFlags,
    isNewTocExists,
    patientDetails,
    isTocsLoading,
    openReasonModal,
    dropdownRef,
    updateState,
    getTocsList,
    patientDetailsDisplay,
    handleSubmitClick,
    selectedToc,
    resetFormDetails,
    onClickCustomisedCarePlan,
  } = props;
  const tocEditForm = useSelector(getTocEditForm);
  const isStandardRisk =
    !isEmptyString(riskLevel) &&
    riskLevel === RiskLevelEnum.STANDARD &&
    !isEmptyString(isPlanned) &&
    isPlanned === PlannedUnplannedEnum.PLANNED;
  const dispatch = useDispatch();
  const { currentTab } = useSelector(getBrowserState);
  const { taskId, intakeId } = currentTab!;

  return (
    <>
      {reasonModalPayload?.isOpen && <RevisionReasonModal {...reasonModalPayload} />}
      {modal.isOpenAddServiceModal.isOpen && <AddServiceModal />}
      {modal.isOpenTocReasonModal && isStandardRisk && (
        <TocReasonModal onClickCustomisedCarePlan={onClickCustomisedCarePlan} />
      )}
      {tocEditForm.isSubmitModalOpen && (
        <GenericPopupModal
          submitButtonTitle={"Confirm"}
          content={submitSubheading}
          isOpen={tocEditForm.isSubmitModalOpen}
          handleCancelClick={() => {
            dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: false }));
          }}
          handleSubmitClick={handleSubmitClick}
          cancelButtonVisible
        ></GenericPopupModal>
      )}

      {tocEditForm.isCancelModalOpen && selectedToc && (
        <GenericPopupModal
          submitButtonTitle="OK"
          content={cancelSubheading}
          isOpen={tocEditForm.isCancelModalOpen}
          handleCancelClick={() => {
            dispatch(setTocEditForm({ ...tocEditForm, isCancelModalOpen: false }));
          }}
          handleSubmitClick={() => {
            dispatch(setTocEditForm({ ...tocEditForm, isCancelModalOpen: false }));
            resetFormDetails();
            dispatch(setLockState({ intakeId, taskId, isLocked: false }));
            dispatch(removeTab(currentTab?.taskId));
          }}
          cancelButtonVisible
        ></GenericPopupModal>
      )}
      <div className="container">
        <div className="revisons">
          <div className="heading">
            <div className="info-container">
              <div className="patient-info">
                {!patientDetails ? null : (
                  <>
                    <Header className="toc" patientDetails={patientDetailsDisplay()} title={tocTitle} />
                  </>
                )}
              </div>
            </div>
            <div className="cta-container">
              <div className="new-revision-container">
                {isTocsLoading || isNewTocExists ? null : (
                  <Button
                    text="Create New Revision"
                    className={`green-button new-revision-cta`}
                    showLoader={false}
                    disabled={isTocsLoading}
                    onClick={() => {
                      openReasonModal();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`revision-table-container`}>
            <div
              className="revision-table"
              onClick={() => {
                dropdownRef.current = setTimeout(() => {
                  updateState();
                }, 100);
              }}
            >
              <div
                className={`revision-table-header ${
                  hasScrollBar
                    ? `revision-table-header-scrollbar-is-visible ${
                        featureFlags.taskManagement ? "task-details-visible" : ""
                      }`
                    : ""
                }`}
              >
                {TOC_LISTING_HEADERS.map(({ name }) => {
                  return (
                    <div className="revision-table-header-cell" key={name}>
                      <div className="revision-table-header-content">
                        <div className="name">{name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="revision-table-body">
                {isTocsLoading ? <CommonState type={CommonStateType.LOADING} /> : getTocsList()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransitionOfCarePresentation;
