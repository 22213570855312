import { createSlice } from "@reduxjs/toolkit";
import {
  createTocAsync,
  editTocAsync,
  findDistance,
  getAchFacilities,
  getAllTocFacilitiesAsync,
  getCarePlans,
  getCustomiseReasons,
  getIntakeAsync,
  getIntakeDetails,
  getPacTypes,
  getServiceTemplateData,
  getTocAsync,
  getTocHistory,
  getTocRevisionReasons,
  getTocRiskLevel,
  getTocStatuses,
} from "./toc-management.action";
import { toast } from "react-toastify";
import {
  CarePlanData,
  IFacility,
  ILocation,
  ITOCManagementState,
  ITocEditForm,
  ITransitionOfCare,
} from "state/types/toc-management-slice.type";
import { convertMetersToMiles } from "shared/methods/utilityFunctions";
import { PAC_TYPE, TOC_ITEMS, TocStatusEnum } from "pages/task-management/transition-of-care/constants/index.constant";

const initialState: ITOCManagementState = {
  isLoading: false,
  intake: null,
  physicianId: "",
  isTocsLoading: true,
  tocs: [],
  facilities: [],
  allFacilities: [],
  revisionReasons: [],
  statuses: [],
  isTocValueChanged: false,
  carePlans: [],
  carePlanData: [],
  originalCarePlanData: [],
  riskLevel: "",
  isPlanned: "",
  tocCustomiseReason: { id: "", name: "" },
  isCustomisedCarePlanEnabled: false,
  pacTypes: [],
  customiseReasons: [],
  tocEditForm: {
    losError: "",
    acuteLos: {
      value: "",
      error: "",
    },
    navigatorNotes: null,
    physicianNotes: null,
    additionalNotes: null,
    isHomeWithNoServicesEnabled: false,
    tocItemsForm: TOC_ITEMS,
    facilityError: "",
    isClearModalOpen: false,
    isCancelModalOpen: false,
    isSubmitModalOpen: false,
    isTocItemsFormDirty: false,
    isFormEmpty: false,
    isChangeModalOpen: false,
    editTocDetails: null,
  },
};
const tocManagementSlice = createSlice({
  name: "toc-management",
  initialState: Object.assign({}, initialState),
  reducers: {
    updateTocList(state, action) {
      state.tocs = action.payload;
    },
    addNewTocRevision(state, action) {
      state.tocs = [action.payload, ...state.tocs];
    },
    setIsTocsLoading(state, action) {
      state.isTocsLoading = action.payload;
    },
    setIsTocValueChanged(state, action) {
      state.isTocValueChanged = action.payload;
    },
    addNewTocService(state, action) {
      state.carePlanData.push(action.payload);
      state.tocEditForm.facilityError = "";
    },
    deleteToc(state, action) {
      const selectedTocToBeDeleted = state.tocs.findIndex((toc) => toc.version === action.payload);
      const copyOfTocs = [...state.tocs];
      copyOfTocs.splice(selectedTocToBeDeleted, 1);
      state.tocs = copyOfTocs;
    },
    changeTocStatus(state, action) {
      const copyOfTocs = [...state.tocs];
      copyOfTocs[action.payload].status = TocStatusEnum.PENDING;
      state.tocs = copyOfTocs;
    },
    editTocService(state, action) {
      const index = state.carePlanData.findIndex((carePlan) => carePlan.longName === action.payload.longName);
      state.carePlanData[index] = {
        ...state.carePlanData[index],
        longName: action.payload.longName,
        providerId: action.payload.providerId,
        quantity: action.payload.quantity,
        isPrefferedProvider: action.payload.prefferedProvider,
      };
    },
    setEditTocDetails(state, action) {
      state.tocEditForm.editTocDetails = action.payload;
    },
    resetTocState() {
      return initialState;
    },
    setTocManagementStateFromMemoize(state, action) {
      return action.payload;
    },
    setTocEditForm(state, action) {
      state.tocEditForm = action.payload;
    },
    setTocItemsForm(state, action) {
      state.tocEditForm.tocItemsForm = action.payload;
    },
    setCarePlanData(state, action) {
      state.carePlanData = action.payload.map((item: CarePlanData, index: number) => {
        return {
          admission: item.admission,
          daysType: item.daysType,
          longName: item.longName,
          pacTypeId: item.pacTypeId,
          quantity: item.quantity,
          sequence: index,
          shortName: "",
          standardCarePlanId: -1,
          id: item.id,
          providerId: item.providerId,
        };
      });
    },
    setOriginalCarePlanData(state, action) {
      state.originalCarePlanData = action.payload.map((item: CarePlanData, index: number) => {
        return {
          admission: item.admission,
          daysType: item.daysType,
          longName: item.longName,
          pacTypeId: item.pacTypeId,
          quantity: item.quantity,
          sequence: index,
          shortName: "",
          standardCarePlanId: -1,
          id: item.id,
          providerId: item.providerId,
        };
      });
    },
    setHomeWithoutNoService(state, action) {
      state.tocEditForm.isHomeWithNoServicesEnabled = action.payload;
    },
    resetTocItemsForm(state) {
      state.tocEditForm.tocItemsForm = structuredClone(TOC_ITEMS);
    },
    resetTocEditForm(state) {
      state.tocEditForm = structuredClone(initialState.tocEditForm);
    },
    updateAchFacilites(state, action) {
      state.facilities = action.payload;
    },
    setNavigatorAndAdditionalNotes: (state, action) => {
      state.tocEditForm.navigatorNotes = action.payload.navigatorNotes;
      state.tocEditForm.additionalNotes = action.payload.additionalNotes;
    },
    resetLocationValidation(state) {
      const tocItems: Record<string, ILocation> = {};
      Object.entries(state.tocEditForm.tocItemsForm).forEach(([key, location]) => {
        location.isLosValid = true;
        location.isProviderValid = true;
        tocItems[key] = location;
      });
      state.tocEditForm.tocItemsForm = tocItems;
    },
    setAchDistance(state, action) {
      const facilities: IFacility[] = [];
      const selectedPlaces = action.payload.selectedPlaces;
      const response = action.payload.data;
      const selectedFacilityIds = selectedPlaces.map((el: any) => el.id);

      state.facilities.forEach((fac: IFacility) => {
        if (selectedFacilityIds.includes(fac.id)) {
          const index = selectedFacilityIds.findIndex((el: any) => el === fac.id);
          const entry = response.rows[0].elements[index];
          if (entry.distance) {
            const distance = convertMetersToMiles(entry.distance.value);
            facilities.push({ ...fac, distance } as IFacility);
          } else {
            facilities.push({ ...fac } as IFacility);
          }
        } else {
          facilities.push({ ...fac } as IFacility);
        }
      });

      facilities.sort((a, b) => {
        const distA = a.distance.length ? parseFloat(a.distance.split(" ")[0]) : Number.MAX_SAFE_INTEGER;
        const distB = b.distance.length ? parseFloat(b.distance.split(" ")[0]) : Number.MAX_SAFE_INTEGER;

        return distA - distB;
      });
      state.facilities = facilities;
    },
    setAllFacilitiesDistance(state, action) {
      const facilities: IFacility[] = [];
      const selectedPlaces = action.payload.selectedPlaces;
      const response = action.payload.data;
      const selectedFacilityIds = selectedPlaces.map((el: any) => el.id);

      state.allFacilities.forEach((fac: IFacility) => {
        if (selectedFacilityIds.includes(fac.id)) {
          const index = selectedFacilityIds.findIndex((el: any) => el === fac.id);
          const entry = response.rows[0].elements[index];
          if (entry.distance) {
            const distance = convertMetersToMiles(entry.distance.value);
            facilities.push({ ...fac, distance } as IFacility);
          } else {
            facilities.push({ ...fac } as IFacility);
          }
        } else {
          facilities.push({ ...fac } as IFacility);
        }
      });

      facilities.sort((a, b) => {
        const distA = a.distance.length ? parseFloat(a.distance.split(" ")[0]) : Number.MAX_SAFE_INTEGER;
        const distB = b.distance.length ? parseFloat(b.distance.split(" ")[0]) : Number.MAX_SAFE_INTEGER;

        return distA - distB;
      });
      state.allFacilities = facilities;
    },
    setTocCustomiseReason(state, action) {
      state.tocCustomiseReason = action.payload;
    },
    setIsCustomisedCarePlanEnabled(state, action) {
      state.isCustomisedCarePlanEnabled = action.payload;
    },
    setCustomiseReasonForSelectedToc(state, action) {
      const copyOfTocs = [...state.tocs];
      copyOfTocs[0].customizeReasonId = action.payload;
      state.tocs = copyOfTocs;
    },
    deletePacTypeItem(state, action) {
      const index = state.carePlanData.findIndex((carePlan) => carePlan.longName === action.payload);
      const copyOfCarePlanData = [...state.carePlanData];
      if (index !== -1) {
        copyOfCarePlanData.splice(index, 1);
        state.carePlanData = copyOfCarePlanData;
      }
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getTocAsync.pending, (state) => {
        state.isTocsLoading = true;
        state.tocs = [];
      }),
      builder.addCase(getTocAsync.fulfilled, (state, action) => {
        state.isTocsLoading = false;
        state.tocs = action.payload;
      }),
      builder.addCase(getTocAsync.rejected, (state, action: any) => {
        state.isTocsLoading = false;
        state.tocs = [];
      }),
      builder.addCase(getAchFacilities.fulfilled, (state, action) => {
        state.facilities = action.payload;
      }),
      builder.addCase(getAllTocFacilitiesAsync.fulfilled, (state, action) => {
        state.allFacilities = action.payload;
      }),
      builder.addCase(getIntakeAsync.fulfilled, (state, action) => {
        state.intake = action.payload;
      }),
      builder.addCase(getIntakeDetails.fulfilled, (state, action) => {
        state.physicianId = action.payload;
      }),
      builder.addCase(createTocAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(createTocAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      }),
      builder.addCase(createTocAsync.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(editTocAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(editTocAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("TOC updated successfully", {
          containerId: "main",
        });
      }),
      builder.addCase(editTocAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(getTocHistory.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getTocHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        const currentTocIdx = state.tocs.findIndex((el) => el.id === action.meta.arg);
        state.tocs[currentTocIdx].history = action.payload;
      }),
      builder.addCase(getTocHistory.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(getTocRevisionReasons.fulfilled, (state, action) => {
        state.revisionReasons = action.payload;
      }),
      builder.addCase(getTocStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload;
      }),
      builder.addCase(getCarePlans.fulfilled, (state, action) => {
        state.carePlans = action.payload;
      }),
      builder.addCase(getServiceTemplateData.fulfilled, (state, action) => {
        const existingCarePlanData = [...state.carePlanData].filter((carePlan) => carePlan.providerId !== null);
        const newCarePlanData = [...action.payload];
        const overrideExisitngCarePlan = existingCarePlanData.map((carePlan) => {
          const longNameItem = newCarePlanData.find((carePlanItem: any) => carePlan.longName === carePlanItem.longName);
          if (longNameItem) {
            const newCarePlan = {
              ...longNameItem,
              quantity: carePlan.quantity,
              providerId: carePlan.providerId,
            };
            const carePlanIndex = newCarePlanData.findIndex(
              (carePlanItem: any) => carePlan.longName === carePlanItem.longName
            );
            newCarePlanData.splice(carePlanIndex, 1);
            return newCarePlan;
          } else {
            return carePlan;
          }
        });

        const isHomeWithoutServicePresent = action.payload.find(
          (carePlanItem: any) => carePlanItem.longName === PAC_TYPE.HOME_SERVICE
        );
        state.tocEditForm.isHomeWithNoServicesEnabled = isHomeWithoutServicePresent ? true : false;
        state.carePlanData = [...overrideExisitngCarePlan, ...newCarePlanData].filter(
          (carePlanItem: any) => carePlanItem.longName !== PAC_TYPE.HOME_SERVICE
        );
      }),
      builder.addCase(getTocRiskLevel.fulfilled, (state, action) => {
        state.riskLevel = action.payload.riskLevel;
        state.isPlanned = action.payload.plannedUnplanned;
      }),
      builder.addCase(getPacTypes.fulfilled, (state, action) => {
        state.pacTypes = action.payload;
      }),
      builder.addCase(getCustomiseReasons.fulfilled, (state, action) => {
        state.customiseReasons = action.payload;
      })
    );
  },
});

export const {
  addNewTocRevision,
  updateTocList,
  setIsTocsLoading,
  setIsTocValueChanged,
  resetTocState,
  setTocEditForm,
  setTocItemsForm,
  resetTocItemsForm,
  setTocManagementStateFromMemoize,
  resetTocEditForm,
  resetLocationValidation,
  setNavigatorAndAdditionalNotes,
  addNewTocService,
  editTocService,
  setEditTocDetails,
  setCarePlanData,
  setHomeWithoutNoService,
  updateAchFacilites,
  setOriginalCarePlanData,
  setAchDistance,
  setAllFacilitiesDistance,
  setTocCustomiseReason,
  setIsCustomisedCarePlanEnabled,
  deletePacTypeItem,
  setCustomiseReasonForSelectedToc,
  deleteToc,
  changeTocStatus,
} = tocManagementSlice.actions;
export const getTocs = (state: any): ITransitionOfCare[] => state.tocManagement.tocs;
export const getTOCManagementState = (state: any): ITOCManagementState => state.tocManagement;
export const getTocEditForm = (state: any): ITocEditForm => state.tocManagement.tocEditForm;
export default tocManagementSlice;
